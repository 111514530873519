.navbar {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-logo img {
    height: 50px;
    width: 50px;
  }
  
  .navbar-search {
    width: 500px;
    position: relative;
    /* flex-grow: 1; */
    margin: 3px 70px;
    padding: 0px ;
  }
  
  .navbar-search input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  
  .search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .navbar-icons {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
  
  .icon-group {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-right: 15px;
  }
  
  .icon-group span {
    cursor: pointer;
    font-size: 20px;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
  
  .icon-group span:hover {
    opacity: 1;
  }
  
  .notification-icon {
    position: relative;
    padding-top: 4px;
    padding-left: 50px;
  }
  
  .notification-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
  
  .profile-container {
    position: relative;
    padding-left: 50px;
  }
  
  .profile-icon {
    cursor: pointer;
    font-size: 24px;
  }
  
  .profile-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    width: 250px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    margin-top: 10px;
  }
  
  .profile-header {
    padding: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .profile-header p:first-child {
    font-weight: bold;
    margin: 0;
  }
  
  .profile-header p:last-child {
    color: #666;
    font-size: 0.8em;
    margin: 5px 0 0;
  }
  
  .profile-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .profile-dropdown li {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .profile-dropdown li:hover {
    background-color: #f5f5f5;
  }
  
  .profile-dropdown .logout {
    color: red;
  }